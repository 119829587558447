import styled from 'styled-components'
import { colors, screenSizes } from 'styles/theme'

export const WalletCardContainer = styled.div<{ isActive?: boolean }>`
  border: ${(props) =>
    props.isActive ? `2px solid ${colors.primary}` : ' 1px solid grey'};
  padding: 22px 16px;
  text-align: center;
  position: relative;

  border-right: 4px solid ${colors.primary};

  cursor: pointer;
  &:hover {
    filter: brightness(75%);
  }

  p {
    font-family: var(--font-dm-mono);
    font-weight: 500;
    font-size: 14px;

    @media (min-width: ${screenSizes.XS}px) {
      font-size: 16px;
    }
  }

  &::before {
    content: '';
    height: 45px;
    width: 70px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    position: absolute;
    border: 1px solid #2393b8;
    border-right: none;
    border-bottom: none;
  }

  @media (min-width: ${screenSizes.XS}px) {
    padding: 16px 24px;
    p {
      font-size: 16px;
    }
  }
`
