import * as yup from 'yup'

export const passwordSchema = yup.object().shape({
  createPassword: yup
    .string()
    .required('Password is required')
    .matches(/^\S*$/, 'Should not have spaces')
    .min(8, 'Minimum length is 8')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Your password should consist of minimum 8 characters, One uppercase, One lowercase, One numeric and One special character'
    )
    .max(32, 'Maximum length is 32'),

  confirmCreatePassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('createPassword'), ''], 'Passwords must match')
    .required('Confirm Password is required'),
})

export const editProfileSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First Name is required')
    .matches(/^[a-zA-Z]+$/, 'First Name should only contain alphabets'),
  lastName: yup
    .string()
    .required('Last Name is required')
    .matches(/^[a-zA-Z]+$/, 'Last Name should only contain alphabets'),
})
