import Image, { StaticImageData } from 'next/image'

import { GlitchContainer } from './style'
import LogoSvg from 'public/assets/landing/logo.svg'

interface IGlitchLogoProps {
  className?: string
  logoSrc?: StaticImageData
}

const GlitchLogo = ({ className, logoSrc = LogoSvg }: IGlitchLogoProps) => {
  return (
    <GlitchContainer logoSrc={logoSrc} className={className}>
      <Image src={logoSrc} alt='brand-logo' />
      <div className='glitch__layers'>
        <div className='glitch__layer'></div>
        <div className='glitch__layer'></div>
        <div className='glitch__layer'></div>
      </div>
    </GlitchContainer>
  )
}

export default GlitchLogo
