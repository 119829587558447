import styled from 'styled-components'
import Image from 'next/image'

import CustomModal from './CustomModal'
import Modal from './Modal'
import * as Styles from './style'
import { FlexCol } from 'styles/styled'
import { setupNetwork } from 'src/blockchain'
import { setShowWrongNetworkModal } from 'src/logic/redux/slices/uiSlice'
import { useAppDispatch } from 'src/logic/redux/hooks'
import PrimaryButton from '../mintButton/MintButton'
import SecondaryButton from '../secondaryButton/SecondaryButton'
import { useConnectWallet } from 'src/blockchain/hooks/useConnectWallet'

export interface IInfoModalProps {
  heading?: string
  info?: string
  icon?: string
  show: boolean
  handleClose?: () => void
  onBtnClick?: () => void
  transactionHash?: string
  transactionStatus?: string
}

const WrongNetworkModal = (props: IInfoModalProps) => {
  const { handleClose, heading, info, show, onBtnClick, icon = '' } = props

  const dispatch = useAppDispatch()

  const { deactivate } = useConnectWallet()

  const closeWrongNetworkModal = () => {
    dispatch(setShowWrongNetworkModal(false))
  }

  const disconnectWallet = () => {
    deactivate()
    closeWrongNetworkModal()
  }

  return (
    <CustomModal show={show} handleClose={handleClose}>
      <Modal onBtnClick={onBtnClick} btnText='Close' hideBtn={true}>
        <Styles.InfoModalContainer>
          <FlexCol gap='12px' alignItems='center'>
            <Styles.ImageCont>
              <Image src={icon} alt='welcome-icon' />
            </Styles.ImageCont>
            <h4>{heading}</h4>
            <p>{info}</p>

            <WrappedDiv>
              <PrimaryButton
                onClick={() =>
                  setupNetwork(() => {
                    closeWrongNetworkModal()
                  })
                }
                fillOpacity='0.5'
              >
                PolyGon
              </PrimaryButton>
              <br />
              <SecondaryButton
                onClick={() => disconnectWallet()}
                bgColor='#B7001833'
                borderColor='#B7001833'
                fillOpacity='0.5'
              >
                Disconnect
              </SecondaryButton>
            </WrappedDiv>
          </FlexCol>
        </Styles.InfoModalContainer>
      </Modal>
    </CustomModal>
  )
}

export default WrongNetworkModal

export const SwitchModalHeading = styled.div`
  color: ${(props) => props.theme.colors.fontColor13};
`
export const WrappedDiv = styled.div`
  margin: 10px 0px;
  hr {
    margin: 5px 0px;
  }
  width: 90%;
`

export const ChainName = styled.div`
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: '#E04851';
  padding: 10px;
  cursor: pointer;
  :hover {
    border-radius: 5px;
  }
`
