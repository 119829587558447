type Props = {
  fill?: string
  fillOpacity?: string
}

export function LeftCorner(props: Props) {
  return (
    <svg
      width='30'
      height='50'
      viewBox='0 0 30 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_iii_1849_39041)'>
        <path
          d='M30 0H20L0 25L20 50H30V0Z'
          fill={props?.fill || '#2393B8'}
          fillOpacity={props?.fillOpacity || '0.01'}
        />
      </g>
      <defs>
        <filter
          id='filter0_iii_1849_39041'
          x='0'
          y='0'
          width='30'
          height='50'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.137255 0 0 0 0 0.576471 0 0 0 0 0.721569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1849_39041'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='-1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.137255 0 0 0 0 0.576471 0 0 0 0 0.721569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_innerShadow_1849_39041'
            result='effect2_innerShadow_1849_39041'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.137255 0 0 0 0 0.576471 0 0 0 0 0.721569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect2_innerShadow_1849_39041'
            result='effect3_innerShadow_1849_39041'
          />
        </filter>
      </defs>
    </svg>
  )
}

export function RightCorner(props: Props) {
  return (
    <svg
      width='30'
      height='50'
      viewBox='0 0 30 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_iii_1849_39044)'>
        <path
          d='M0 0H10L30 25L10 50H0V0Z'
          fill={props?.fill || '#2393B8'}
          fillOpacity={props?.fillOpacity || '0.01'}
        />
      </g>
      <defs>
        <filter
          id='filter0_iii_1849_39044'
          x='0'
          y='0'
          width='30'
          height='50'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.137255 0 0 0 0 0.576471 0 0 0 0 0.721569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1849_39044'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='-1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.137255 0 0 0 0 0.576471 0 0 0 0 0.721569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_innerShadow_1849_39044'
            result='effect2_innerShadow_1849_39044'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-1.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.137255 0 0 0 0 0.576471 0 0 0 0 0.721569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect2_innerShadow_1849_39044'
            result='effect3_innerShadow_1849_39044'
          />
        </filter>
      </defs>
    </svg>
  )
}
