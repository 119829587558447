import styled from 'styled-components'

import { FlexCol } from 'styles/styled'
import { colors, screenSizes } from 'styles/theme'

export const ModalContainer = styled.div`
  position: relative;
  @media (min-width: ${screenSizes.M}px) {
    padding: 26px;
  }

  .close {
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;

    filter: brightness(75%);
    transition: transform 0.3s ease-in-out;

    &:hover {
      filter: brightness(100%);
      transform: scale(1.3);
    }
  }
`

export const CustomModalContainer = styled.div<any>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  transition: all 1s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const CustomModalContainerBackgroundBlur = styled.div<any>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  backdrop-filter: blur(10px);
  background: ${colors.modalOverlay};

  transition: all 1s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  max-height: 90%;
  width: 661px;

  background-repeat: no-repeat;

  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;

  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 750px) {
    width: 90%;
  }

  &.mint-modal {
    max-height: 100%;

    ${ModalContainer} {
      padding: 0;
      padding-bottom: 5px;
    }

    @media (min-width: ${screenSizes.M}px) {
      max-height: 100%;
    }
  }

  &.status-modal {
    background: none;
  }

  &.choose-nft {
    border: 1px solid ${colors.primary};
    width: 95%;
    background: ${colors.modalBackground};
    overflow-y: scroll;
    padding: 20px 10px;

    @media (min-width: ${screenSizes.M}px) {
      width: 65%;
    }
  }
`

// ==================== Modal ====================

export const HeadingCont = styled(FlexCol)`
  gap: 16px;
  text-align: center;
  justify-content: center;
  h4 {
    margin: 0 auto;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    font-family: var(--font-tomorrow);
  }

  p {
    font-family: var(--font-dm-mono);
    font-weight: 400;
    font-size: 14px;
    align-self: center;

    span {
      font-family: var(--font-tomorrow);
      color: ${colors.primary};
    }
  }

  @media (min-width: ${screenSizes.XS}px) {
    p {
      font-size: 16px;
    }

    h4 {
      font-size: 24px;
    }
  }
`

export const ModalContent = styled(FlexCol)`
  gap: 36px;

  hr {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  & > div {
    width: 100%;
  }
`

// ==================== InfoModal ====================

export const ImageCont = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    margin-left: auto;
    filter: brightness(75%);
    transition: transform 0.3s ease-in-out;

    &:hover {
      filter: brightness(100%);
      transform: scale(1.3);
    }
  }

  &.success {
    width: max-content;
    margin: 0 auto;
    align-items: center;
  }
`

export const InfoModalContainer = styled.div`
  text-align: center;
  position: relative;

  .close {
    position: absolute;
    right: 20px;
    top: 0;

    filter: brightness(75%);
    transition: transform 0.3s ease-in-out;

    &:hover {
      filter: brightness(100%);
      transform: scale(1.3);
    }
  }

  h4 {
    font-family: var(--font-tomorrow);
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    text-transform: uppercase;

    @media (min-width: ${screenSizes.XS}px) {
      text-align: center;
    }
  }

  p {
    font-family: var(--font-dm-mono);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  &.status {
    ${ImageCont} {
      height: fit-content;
      justify-content: flex-start;
    }
  }

  .status-text {
    align-items: flex-start;

    p {
      text-align: left;
    }
    @media (min-width: ${screenSizes.XS}px) {
      align-items: center;
    }
    @media (min-width: ${screenSizes.XS}px) {
      align-items: flex-start;
    }
  }

  .transaction-status {
    h4 {
      font-family: var(--font-tomorrow);
      text-align: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-transform: capitalize;
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }

    @media (min-width: ${screenSizes.XS}px) {
      h4 {
        font-size: 14px;
      }
    }
  }

  .error {
    color: ${colors.error};
  }

  @media (min-width: ${screenSizes.XS}px) {
    p {
      font-size: 16px;
    }

    h4 {
      font-size: 24px;
    }
  }
`
