import styled from 'styled-components'

import { FlexCol, FlexRow } from 'styles/styled'
import { screenSizes } from 'styles/theme'
import FooterIcon from 'public/assets/footer/footerIcon.svg'

export const FooterCont = styled.div`
  padding: 60px 20px;
  padding-bottom: 120px;
  @media (min-width: ${screenSizes.M}px) {
    padding: 60px 72px;
    padding-bottom: 120px;
  }

  &.overlay {
    background: rgba(25, 59, 77, 0.7);
    backdrop-filter: blur(10px);
    filter: blur(50px);
  }
`

export const AddressCont = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 30px;
  ${FlexCol} {
    width: max-content;
  }

  ${FlexRow} {
    flex-wrap: wrap;
  }

  p {
    font-family: var(--font-tomorrow);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  a {
    font-family: var(--font-tomorrow);
    font-weight: 500;
    font-size: 14px;
  }

  @media (min-width: ${screenSizes.M}px) {
    flex-wrap: nowrap;
  }
`

export const ImageBg = styled.div`
  width: 38px;
  height: 38px;

  border-radius: 0px;
  padding: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${FooterIcon?.src});

  &:hover {
    filter: brightness(75%);
  }
`

export const LinksCont = styled(FlexRow)`
  align-items: center;
`

export const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
  align-self: center;
  padding-top: 7px;
`
