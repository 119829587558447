import { useState } from 'react'
import { useRouter } from 'next/router'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'

import Footer from 'shared/components/footer'
import Navbar from 'shared/components/navbar'
import { ChildCont } from './style'
import { useAppDispatch, useAppSelector } from 'src/logic/redux/hooks'
import ConnectModal from 'shared/components/modals/ConnectModal'
import {
  setShowSuccessModal,
  setShowWelcomeModal,
  setSuccessMessage,
} from 'src/logic/redux/slices/uiSlice'
import InfoModal from 'shared/components/modals/InfoModal'
import { SuccessIcon, WelcomeSvg } from 'shared/images'

import ConnectWalletStatusModal from 'shared/components/modals/ConnectWalletStatusModal'

interface ILayoutProps {
  children: React.ReactNode
  hideFooter?: boolean
}

const Layout = ({ children, hideFooter }: ILayoutProps) => {
  const {
    showConnectModal,
    showWelcomeModal,
    showSuccessModal,
    showConnectWalletStatusModal,
  } = useAppSelector((state) => state.uiReducer)
  const dispatch = useAppDispatch()
  const { pathname } = useRouter()

  const { resetingPassword } = useAppSelector((state) => state.authReducer)
  const { successMessage } = useAppSelector((state) => state.uiReducer)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const getNavClassName = () => (isMenuOpen ? 'overlay' : '')

  return (
    <>
      <Navbar setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
      <ChildCont
        className={
          pathname === '/payment'
            ? `payment ${getNavClassName()}`
            : getNavClassName()
        }
        onClick={() => {
          if (isMenuOpen) setIsMenuOpen(false)
        }}
      >
        {children}
      </ChildCont>
      {showConnectModal && <ConnectModal />}
      {showConnectWalletStatusModal && <ConnectWalletStatusModal />}
      {showWelcomeModal && !resetingPassword && (
        <InfoModal
          show={showWelcomeModal}
          handleClose={() => {
            dispatch(setShowWelcomeModal(false))
          }}
          heading='WELCOME to vtr'
          info='By purchasing NFTs on VTR, you will be eligible for a free subscription of VTR Connect and also receive other rewards and benefits.'
          icon={WelcomeSvg}
        />
      )}

      {!hideFooter && (
        <Footer setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
      )}

      {showSuccessModal && (
        <InfoModal
          show={showSuccessModal}
          handleClose={() => {
            dispatch(setSuccessMessage(''))
            dispatch(setShowSuccessModal(false))
          }}
          heading='Success!!'
          info={successMessage}
          icon={SuccessIcon}
        />
      )}
    </>
  )
}

export default Layout
