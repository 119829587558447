import Image from 'next/image'

import { FlexCol } from 'styles/styled'
import * as Styles from './style'
import SplitCard from '../cards/SplitCard'
import PrimaryButton from '../mintButton/MintButton'
import { CloseSvg } from 'shared/images'

interface IModalProps {
  children: React.ReactNode
  btnText?: string
  isBtnDisabled?: boolean
  onBtnClick?: () => void
  heading?: string
  info?: string | React.ReactNode
  hideBtn?: boolean
  isLoading?: boolean
  headingContentGap?: string
  fillOpacity?: string
  className?: string
  isChoseNft?: boolean
  hasCloseBtn?: boolean
  onCloseClick?: () => void
}

const Modal = (props: IModalProps) => {
  const {
    children,
    onBtnClick,
    heading,
    info,
    headingContentGap,
    isLoading,
    fillOpacity,
    className,
    isChoseNft,
    hasCloseBtn,
    onCloseClick,
    hideBtn = false,
    btnText = 'Continue',
    isBtnDisabled = false,
  } = props
  return (
    <>
      {isChoseNft ? (
        <Styles.ModalContainer>
          {hasCloseBtn ? (
            <Image
              src={CloseSvg}
              alt='close-icon'
              className='close'
              onClick={onCloseClick}
            />
          ) : null}
          <FlexCol gap={headingContentGap || '36px'}>
            {heading || info ? (
              <Styles.HeadingCont>
                <h4>{heading}</h4>
                {info && <p>{info}</p>}
              </Styles.HeadingCont>
            ) : null}

            <Styles.ModalContent>
              <div className={className}>{children}</div>
              {!hideBtn && (
                <div>
                  <PrimaryButton
                    onClick={onBtnClick}
                    disabled={isLoading || isBtnDisabled}
                    isLoading={isLoading}
                    fillOpacity={fillOpacity}
                  >
                    {btnText}
                  </PrimaryButton>
                </div>
              )}
            </Styles.ModalContent>
          </FlexCol>
        </Styles.ModalContainer>
      ) : (
        <SplitCard className={className}>
          <Styles.ModalContainer>
            {hasCloseBtn ? (
              <Image
                src={CloseSvg}
                alt='close-icon'
                className='close'
                onClick={onCloseClick}
              />
            ) : null}
            <FlexCol gap={headingContentGap || '36px'}>
              {heading || info ? (
                <Styles.HeadingCont>
                  <h4>{heading}</h4>
                  {info && <p>{info}</p>}
                </Styles.HeadingCont>
              ) : null}

              <Styles.ModalContent>
                <div>{children}</div>
                {!hideBtn && (
                  <div>
                    <PrimaryButton
                      onClick={onBtnClick}
                      disabled={isLoading || isBtnDisabled}
                      isLoading={isLoading}
                      fillOpacity={fillOpacity}
                    >
                      {btnText}
                    </PrimaryButton>
                  </div>
                )}
              </Styles.ModalContent>
            </FlexCol>
          </Styles.ModalContainer>
        </SplitCard>
      )}
    </>
  )
}

export default Modal
