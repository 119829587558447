import styled from 'styled-components'

import { colors, screenSizes } from 'styles/theme'
import { ISplitButtonProps } from './SplitButton'

export interface IStyledButtonProps {
  padding?: string
  width?: string
}

export const StyledButton = styled.button<IStyledButtonProps>`
  padding: ${(props) => (props.padding ? props.padding : '12px 20px')};
  outline: none;
  white-space: nowrap;
  position: relative;

  font-family: var(--font-tomorrow);
  font-weight: 500;

  width: ${(props) => (props.width ? props.width : '100%')};
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  border: none;
  color: white;
  outline: none;
  /* border-radius: 30px; */

  &:hover {
    filter: brightness(75%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      filter: brightness(100%);
    }
  }

  border: 1px solid ${colors.primary};
  background: ${colors.buttonBg};

  &.type {
    cursor: default;
    filter: brightness(100%);
  }
`

export const IconButton = styled(StyledButton)<{ borderColor?: string }>`
  padding: 8px 12px;
  border-color: ${(props) => (props.borderColor ? props.borderColor : '')};
  img {
    cursor: inherit;
  }
`

// ================== Split-Button ==================

export const SplitButtonCont = styled.button<ISplitButtonProps>`
  position: relative;
  width: ${(props) => (props.width ? props.width : '100px')};
  height: ${(props) => (props.height ? props.height : '30px')};
  clip-path: polygon(100% 0, 100% 68%, 88% 100%, 0 100%, 0 0);
  cursor: pointer;
  outline: none;
  border: none;
  background-color: ${(props) =>
    props.borderColor ? props.borderColor : '#2393B8'};
  z-index: 2;
  box-sizing: border-box;

  &:hover {
    filter: brightness(75%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
      filter: brightness(100%);
    }
  }

  &.icon {
    clip-path: polygon(100% 0, 100% 70%, 68% 100%, 0 100%, 0 0);
    width: 40px;
    height: 40px;
  }

  &.only-mobile {
    display: block;

    @media (min-width: ${screenSizes.XS}px) {
      display: none;
    }
  }

  &.status {
    width: 80px;
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%, 0 35%);

    span {
      font-size: 11px;
    }
  }

  &.yellow {
    border: 1px solid #fccc75;
    background: rgb(252, 204, 117);
    pointer-events: none;
    user-select: none;
  }
  &.red {
    border: 1px solid #ea0c0c;
    background: rgb(234, 12, 12);
    padding: 4px 10px;
    pointer-events: none;
    user-select: none;
  }
  &.green {
    border: 1px solid #06c270;
    background: rgb(6, 194, 112);
    padding: 4px 10px;
    pointer-events: none;
    user-select: none;
  }
`

export const SplitButtonInside = styled.span<ISplitButtonProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  padding: 12px 0;
  text-transform: uppercase;

  font-family: var(--font-tomorrow);
  font-weight: 500;
  font-size: 12px;
  color: ${colors.white};

  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgba(0, 12, 28, 0.8)'};
  padding: ${(props) => (props.padding ? props.padding : '12px 0')};
  z-index: 4;

  clip-path: inherit;
  @media (min-width: ${screenSizes.XS}px) {
    font-size: 14px;
  }
`
