import { getConfig } from 'config'
import web3 from 'web3'
import { metamask } from './connectors/metamask'
import { walletConnectV2 } from './connectors/walletConnect'

export enum WALLET_TYPES {
  metamask = 'metamask',
  walletConnect = 'walletConnect',
  custodialWallet = 'custodialWallet',
}

export const getConnector = (type: WALLET_TYPES) => {
  switch (type) {
    case WALLET_TYPES.metamask:
      return metamask

    case WALLET_TYPES.walletConnect:
      return walletConnectV2

    default:
      return metamask
  }
}

export const setupNetwork = async (closeWrongNetworkModal?: () => void) => {
  const { ethereum } = window as any
  if (ethereum) {
    try {
      await ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(`${getConfig().chainId}`) }],
      })
      closeWrongNetworkModal?.()
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await ethereum?.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: web3.utils.toHex(`${getConfig().chainId}`),
                chainName: getConfig().chainName,
                rpcUrls: [getConfig().rpcUrl] /* ... */,
                nativeCurrency: {
                  name: getConfig().currencyName,
                  symbol: getConfig().currencySymbol,
                  decimals: 18,
                },
              },
            ],
          })
          closeWrongNetworkModal?.()
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  } else {
    console.error(
      "Can't setup the Etherium network on metamask because window.ethereum is undefined"
    )
    return false
  }
}
