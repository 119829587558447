import Web3 from 'web3'

export const formatAddress = (address: string, firstNum = 6, lastNum = 6) => {
  const length = address.length
  const first = address.slice(0, firstNum)
  const last = address.slice(length - lastNum, length)
  return `${first}...${last}`
}

export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email)
}

export const getPastRelativeTime = (timestampInMs: number) => {
  const now = Date.now()
  const secondsAgo = Math.floor((now - timestampInMs) / 1000)

  const timeUnits = [
    { unit: 'year', duration: 60 * 60 * 24 * 365 },
    { unit: 'month', duration: 60 * 60 * 24 * 30 },
    { unit: 'week', duration: 60 * 60 * 24 * 7 },
    { unit: 'day', duration: 60 * 60 * 24 },
    { unit: 'hour', duration: 60 * 60 },
    { unit: 'minute', duration: 60 },
    { unit: 'second', duration: 1 },
  ]

  for (const element of timeUnits) {
    const { unit, duration } = element
    if (secondsAgo >= duration) {
      const count = Math.floor(secondsAgo / duration)
      return `${count} ${unit}${count > 1 ? 's' : ''} ago`
    }
  }

  return `${secondsAgo} seconds ago`
}

export const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str)

export const isValidAddress = (address: string): boolean => {
  return Web3.utils.isAddress(address)
}

export const toChecksumAddress = (address: string): string => {
  return Web3.utils.toChecksumAddress(address)
}

export const extractIdFromURL = (url: string) => {
  const fileName = url.substring(url.lastIndexOf('/') + 1)

  const match = fileName.match(/(\d+)\.jpg/)

  if (match) {
    return Number(match[1])
  }

  return null
}

export const capitalizeFirstLetter = (title: string) => {
  if (title) {
    return title?.charAt(0).toUpperCase() + title?.slice(1)
  }

  return title
}
