import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

import { LeftCorner, RightCorner } from './Corners'
import { colors, screenSizes } from 'styles/theme'
import { ISplitButtonProps } from '../buttons/SplitButton'
import SmallLoader from '../loader/SmallLoader'
import { SmallLoaderCont } from '../loader/style'

export interface IPrimaryButtonProps
  extends ISplitButtonProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
}

const PrimaryButton = (props: IPrimaryButtonProps) => {
  const {
    bgColor,
    children,
    disabled,
    width,
    isLoading,
    className,
    borderColor,
    fillOpacity,
    ...otherProps
  } = props

  const bg = bgColor ? bgColor : colors.buttonBg

  return (
    <Container className={className} disabled={disabled} width={width}>
      <Inside>
        <LeftCorner fill={bg} fillOpacity={fillOpacity} />
      </Inside>
      <Content
        className={className}
        bgColor={bg}
        disabled={disabled}
        width={width}
        {...otherProps}
      >
        {children}
        {isLoading ? <SmallLoader /> : null}
      </Content>
      <Inside2>
        <RightCorner fill={bg} fillOpacity={fillOpacity} />
      </Inside2>
    </Container>
  )
}

export default PrimaryButton

export const Container = styled.div<{ disabled?: boolean; width?: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: relative;
  width: ${(props) => (props.width ? props.width : 'calc(100% - 60px)')};
  height: 50px;
  margin-left: 30px;
  margin-right: 30px;

  filter: drop-shadow(0px 10px 20px rgba(0, 12, 28, 0.5));

  &:hover {
    filter: brightness(75%);
  }

  ${(props) =>
    props.disabled &&
    `
   cursor: not-allowed;
  opacity: 0.5;

    &:hover {
      filter: brightness(100%);
    }
  
  `}

  &.edit-profile {
    ${SmallLoaderCont} {
      right: -5px;
    }
  }
`
export const Inside = styled.div`
  width: 30px;
  height: 50px;

  flex: none;
  order: 0;
  flex-grow: 0;
`

export const Content = styled.button<ISplitButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '13px 24px')};
  gap: 10px;
  font-family: var(--font-tomorrow);
  font-weight: 500;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;

  width: ${(props) => (props.width ? props.width : '100%')};
  height: 50px;
  color: ${colors.white};

  background: ${(props) => props.bgColor};
  box-shadow: inset 0px -1px 0px #2393b8, inset 0px 1px 0px #2393b8;

  white-space: nowrap;
  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  &:disabled {
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
      filter: brightness(100%);
    }
  }

  &.mint-button {
    font-family: var(--font-tomorrow);
    font-weight: 700;
    font-size: 20px;
    @media (max-width: ${screenSizes.XS}px) {
      padding: 0 1px;
    }
  }
`

export const Inside2 = styled.div`
  box-sizing: border-box;

  width: 30px;
  height: 50px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
`
