import styled from 'styled-components'

import { colors, screenSizes } from 'styles/theme'

export const CardCont = styled.div<{ isSelected?: boolean }>`
  padding: 20px;
  cursor: pointer;

  .options-icon {
    &:hover {
      filter: brightness(75%);
    }
  }

  .add {
    display: none;
    position: absolute;
    right: 14px;
    bottom: 14px;

    @media (min-width: ${screenSizes.XS}px) {
      display: ${(props) => (props.isSelected ? 'block' : 'none')};
    }
  }

  &:hover {
    .add {
      display: block;
    }
  }

  h4 {
    font-family: var(--font-tomorrow);
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

    @media (min-width: ${screenSizes.XS}px) {
      max-width: 200px;
      white-space: nowrap;
    }
  }

  p {
    font-family: var(--font-tomorrow);
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.5);
  }
`

export const ImageCont = styled.div`
  width: 120px;
  height: 120px;
  position: relative;
  overflow: hidden;

  @media (min-width: 428px) {
    width: 140px;
    height: 120px;
  }

  @media (min-width: ${screenSizes.M}px) {
    width: 204px;
  }

  img {
    object-fit: cover;
    clip-path: polygon(0 0, 75% 0%, 100% 20%, 100% 100%, 0 100%, 0% 50%);
  }

  @media (min-width: ${screenSizes.XS}px) {
    height: 204px;
    width: 204px;
  }
`

export const SoldTag = styled.div`
  background: #8f4b98;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-tomorrow);
  font-size: 12px;
  font-weight: 600;
  padding: 5px 12px;
  opacity: 1 !important;
  color: ${colors.white};
  left: 0;
  top: 0;
`

export const CardWrap = styled.div<{ isNftSold?: boolean }>`
  display: flex;
  justify-content: center;
  position: relative;

  ${(props) =>
    props.isNftSold &&
    `
  ${ImageCont}{
    opacity:0.2;
    
  }
  ${CardCont}{
    color: rgba(255, 255, 255, 0.5);    
  }

  .card-content{
    position:relative;
   
  }

  `}
`

// =============== Split-card ===============

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: fit-content;
`
export const MainContainer = styled.div`
  position: relative;
  height: fit-content;

  img {
    cursor: pointer;
  }
`

export const SplitCardContClipTR = styled.div`
  width: 100%;
  height: 100%;

  clip-path: polygon(
    0 0,
    0 100%,
    100% 100%,
    100% 53px,
    calc(100% - 60px) 0
  ); // top right
`

export const SplitCardContClip = styled.div`
  width: 100%;
  height: 100%;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    47px 100%,
    0 calc(100% - 42.57px)
  ); //// bottom left
`

export const TopLeftCont = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  pointer-events: none;
  user-select: none;
`
export const TopRightCont = styled.div`
  position: absolute;
  right: 0;
  z-index: 2;
  top: 0;
  pointer-events: none;
  user-select: none;
`
export const BottomLeftCont = styled.div`
  position: absolute;
  bottom: -4px;
  z-index: 2;
  pointer-events: none;
  user-select: none;
`
export const BottomRightCont = styled.div`
  position: absolute;
  right: 0;
  bottom: -4px;
  z-index: 2;
  pointer-events: none;
  user-select: none;
`

export const TopShadow = styled.div`
  width: 67px;
  height: 58px;
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  user-select: none;

  ::after {
    content: '';
    display: block;
    box-shadow: 20px 25px 15px 5px rgba(35, 147, 184, 0.5);
    transform: rotate(41deg);
  }
`
export const BottomShadow = styled.div`
  width: 50px;
  height: 54px;
  position: absolute;
  bottom: 0px;
  z-index: 2;
  pointer-events: none;
  user-select: none;

  ::after {
    content: '';
    display: block;
    box-shadow: 15px 24px 15px 5px rgba(35, 147, 184, 0.5);
    transform: rotate(44deg);
  }
`

export const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 32%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(14, 240, 239, 0.1) 100%
  );
  z-index: 1;
`

export const TextCont = styled.div`
  border: 1px solid ${colors.borderColor};
  border-bottom: 1.8px solid ${colors.borderColor};
  padding: 32px 16px;
  backdrop-filter: blur(7px);
  background: rgba(0, 12, 28, 0.8);
  backdrop-filter: blur(7px);
  position: relative;
  &.no-padding {
    padding: 0;
  }

  z-index: 100;

  @media (min-width: ${screenSizes.M}px) {
    padding: 30px;
  }

  &.mint-modal {
    padding-bottom: 40px;
  }
`
