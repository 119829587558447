import styled from 'styled-components'
import { screenSizes } from 'styles/theme'

export const ChildCont = styled.div`
  padding-top: 82px;

  @media (min-width: ${screenSizes.M}px) {
    padding-bottom: 0px;
  }

  &.payment {
    overflow-y: hidden;
  }

  &.overlay {
    background: rgba(25, 59, 77, 0.7);
    backdrop-filter: blur(10px);
    filter: blur(50px);
  }
`
