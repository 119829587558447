import Image from 'next/image'

import { ImageCont } from './style'
import LoaderSvg from 'public/assets/gallery/loader.svg'

function Loader() {
  return (
    <ImageCont>
      <Image src={LoaderSvg} alt='welcome-icon' />
    </ImageCont>
  )
}

export default Loader
