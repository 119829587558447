import Image from 'next/image'
import Link from 'next/link'

import { FlexCol, FlexRow } from 'styles/styled'
import * as Styles from './style'
import GlitchLogo from '../glitchLogo'

import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from 'shared/images'
import { useAppDispatch } from 'src/logic/redux/hooks'
import { showToast } from 'src/logic/redux/slices/uiSlice'
import { ToastType } from 'shared/constants'

const SOCIAL_LINKS = [
  {
    icon: FacebookIcon,
    path: 'https://www.facebook.com/vtrnetwork/',
    key: 'facebook',
  },
  {
    icon: InstagramIcon,
    path: 'https://www.instagram.com/vtrconnect/',
    key: 'instagram',
  },
  {
    icon: LinkedinIcon,
    path: 'https://www.linkedin.com/in/vtrconnect-social/',
    key: 'linkedin',
  },
  {
    icon: TwitterIcon,
    path: 'https://twitter.com/VTRConnect',
    key: 'twitter',
  },
  {
    icon: YoutubeIcon,
    path: 'https://www.youtube.com/@vtrconnect2168',
    key: 'youtube',
  },
]

const Footer = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen?: boolean
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const dispatch = useAppDispatch()
  return (
    <Styles.FooterCont
      className={isMenuOpen ? 'overlay' : ''}
      onClick={() => {
        if (isMenuOpen) setIsMenuOpen?.(false)
      }}
    >
      <FlexCol gap='60px'>
        <Link href='/#home'>
          <GlitchLogo />
        </Link>

        <Styles.AddressCont>
          <FlexCol gap='24px'>
            <Link href={'mailto:nftsupport@vtrconnect.com'}>
              nftsupport@vtrconnect.com
            </Link>
            <p>
              VTR SERVICES, LLC <br />
              651 Parkway Suite 201 <br />
              Gatlinburg, TN 37738
            </p>
          </FlexCol>

          <FlexCol gap='24px'>
            <FlexRow gap='16px' width='max-content'>
              {SOCIAL_LINKS.map((item) => (
                <Link
                  target={item?.path ? '_blank' : '_self'}
                  rel='noopener noreferrer'
                  onClick={() => {
                    if (!item?.path) {
                      dispatch(
                        showToast({
                          message: 'Link will be added soon,',
                          toastType: ToastType.success,
                        })
                      )
                    }
                  }}
                  href={item.path ?? null}
                  key={item.key}
                  title={item.key}
                >
                  <Styles.ImageBg>
                    <Image src={item.icon} alt='success-icon' />
                  </Styles.ImageBg>
                </Link>
              ))}
            </FlexRow>

            <Styles.LinksCont>
              <Link
                href={'/privacy-policy'}
                className='hyper-link'
                target={'_blank'}
                rel='noopener noreferrer'
              >
                Privacy Policy
              </Link>

              <Styles.DotContainer>&bull;</Styles.DotContainer>

              <Link
                href={'/terms-and-conditions'}
                className='hyper-link'
                target={'_blank'}
                rel='noopener noreferrer'
              >
                Terms and Conditions
              </Link>
            </Styles.LinksCont>
          </FlexCol>
        </Styles.AddressCont>
      </FlexCol>
    </Styles.FooterCont>
  )
}

export default Footer
