import styled from 'styled-components'

import { FlexRow } from 'styles/styled'
import { colors } from 'styles/theme'

export interface IStyledInputProps {
  bg?: string
  borderColor?: string
  borderRadius?: string
  color?: string
  fontSize?: string
  padding?: string
}

export const StyledInput = styled.input<IStyledInputProps>`
  width: 100%;
  background: ${(props) => (props.bg ? props.bg : colors.inputBg)};

  border: 1px solid
    ${(props) =>
      props.borderColor ? props.borderColor : 'rgba(127, 179, 213, 0.25)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0px'};
  padding: ${(props) => (props.padding ? props.padding : '11px 16px')};
  color: ${(props) => (props.color ? props.color : 'rgba(255, 255, 255, 0.6)')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '13px')};
  outline: none;

  font-family: var(--font-dm-mono);
  font-size: 14px;

  &::placeholder {
    color: 'rgba(255, 255, 255, 0.6)';
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export const InputWithLabelCont = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gap ? props.gap : '8px')};
  position: relative;
  width: 100%;
  background: transparent;

  label {
    font-family: var(--font-tomorrow);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  & > div {
    clip-path: polygon(0% 0px, 100% 0px, 100% 65%, 96% 100%, 0% 99%, 0% 50%);
    padding: 1px;
    background: ${colors.borderColor};

    &:hover {
      background: ${colors.borderHighlight};
    }
  }
`

export const InputInnerCont = styled.div`
  clip-path: inherit;
  padding: 14px 16px;
  background: rgb(26, 26, 26);
  display: flex;
  gap: 2px;

  input {
    background: none;
    border: none;
    padding: 0;
    color: ${colors.white};
  }

  img {
    cursor: pointer;
  }
`

export const InputContainer = styled(FlexRow)<{
  reverseIconPosition?: boolean
}>`
  flex-direction: ${(props) =>
    props.reverseIconPosition ? 'row-reverse' : 'row'};
  padding: 13px 16px;
  border: 1px solid ${colors.borderColor};
  background: rgba(26, 26, 26, 0.8);
  backdrop-filter: blur(7px);
  align-items: flex-start;

  input {
    background: none;
    border: none;
    padding: 0;
    color: ${colors.white};
    font-size: 12px;

    &::placeholder {
      color: ${colors.white};
    }
  }

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &.transparent {
    background: transparent;
    border: none;
    box-shadow: inset 0px -4px 2px 0px rgba(35, 147, 184, 0.1);
    position: relative;
    color: ${colors.white};

    p {
      font-family: var(--font-tomorrow);
      font-size: 12px;
    }
    img {
      height: 16px;
      width: 16px;
    }

    &::before {
      content: '';
      height: 20px;
      width: 20px;
      left: 0px;
      top: 0px;
      border-radius: 0px;
      position: absolute;
      border: 1px solid #2393b8;
      border-right: none;
      border-bottom: none;
    }
    &::after {
      content: '';
      height: 20px;
      width: 20px;
      right: 0px;
      bottom: 0px;
      border-radius: 0px;
      position: absolute;
      border: 1px solid #2393b8;
      border-left: none;
      border-top: none;
    }
  }
`
