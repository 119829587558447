import Image from 'next/image'

import Modal from './Modal'
import CustomModal from './CustomModal'
import { ImageCont, InfoModalContainer } from './style'
import { FlexCol, FlexRow } from 'styles/styled'
import Loader from '../loader'
import { useAppDispatch, useAppSelector } from 'src/logic/redux/hooks'
import { TransactionEnum } from 'src/logic/redux/slices/nftsSlice'
import { setShowConnectWalletStatusModal } from 'src/logic/redux/slices/uiSlice'
import { CloseSvg, ErrorIcon, TickSvg } from 'shared/images'

const ConnectWalletStatusModal = () => {
  const { walletVerificationStatus, walletVerificationMessage } =
    useAppSelector((state) => state.userReducer)

  const icon =
    walletVerificationStatus === TransactionEnum.SUCCESS
      ? TickSvg
      : walletVerificationStatus === TransactionEnum.ERROR
      ? ErrorIcon
      : ''

  const heading =
    walletVerificationStatus === TransactionEnum.STARTED
      ? 'Sign In'
      : walletVerificationStatus === TransactionEnum.HASH
      ? 'Verifying Signature'
      : walletVerificationStatus === TransactionEnum.SUCCESS
      ? 'Confirmed'
      : 'Error'

  const info =
    walletVerificationStatus === TransactionEnum.STARTED
      ? 'Please sign in to affirm your acceptance of connecting wallet to VTR Connect.'
      : ''

  const transactionMessage = walletVerificationMessage || heading

  const dispatch = useAppDispatch()
  return (
    <CustomModal
      show={true}
      handleClose={
        walletVerificationStatus === 'ERROR' ||
        walletVerificationStatus === 'SUCCESS'
          ? () => dispatch(setShowConnectWalletStatusModal(false))
          : () => {}
      }
      className='status-modal'
    >
      <Modal hideBtn={true}>
        <InfoModalContainer className='status'>
          <FlexCol gap='24px'>
            {icon ? (
              <ImageCont>
                <Image src={icon} alt='welcome-icon' />
                {walletVerificationStatus === 'ERROR' ||
                walletVerificationStatus === 'SUCCESS' ? (
                  <Image
                    src={CloseSvg}
                    alt='close-icon'
                    className='close'
                    onClick={
                      walletVerificationStatus === 'ERROR' ||
                      walletVerificationStatus === 'SUCCESS'
                        ? () => dispatch(setShowConnectWalletStatusModal(false))
                        : () => {}
                    }
                  />
                ) : null}
              </ImageCont>
            ) : (
              <Loader />
            )}

            <FlexCol gap='12px' alignItems='center' className='status-text'>
              <h4>{heading}</h4>
              <p>{info}</p>
            </FlexCol>

            <FlexCol
              alignItems='stretch'
              className='transaction-status'
              gap='8px'
            >
              <FlexRow justifyContent='space-between'>
                <p>Status</p>
                <h4
                  className={
                    walletVerificationStatus === 'ERROR' ? 'error' : ''
                  }
                >
                  {transactionMessage}
                </h4>
              </FlexRow>
            </FlexCol>
          </FlexCol>
        </InfoModalContainer>
      </Modal>
    </CustomModal>
  )
}

export default ConnectWalletStatusModal
