import Image from 'next/image'

import CustomModal from './CustomModal'
import Modal from './Modal'
import * as Styled from './style'
import { FlexCol } from 'styles/styled'
import { CloseSvg } from 'shared/images'

export interface IInfoModalProps {
  heading?: string
  info?: string
  icon?: string
  show: boolean
  handleClose?: () => void
  transactionHash?: string
  transactionStatus?: string
}

const InfoModal = (props: IInfoModalProps) => {
  const { handleClose, heading, info, show, icon = '' } = props
  return (
    <CustomModal show={show} handleClose={handleClose}>
      <Modal btnText='Close' fillOpacity='0.5' hideBtn>
        <Styled.InfoModalContainer>
          <FlexCol gap='12px' alignItems='center'>
            <Styled.ImageCont className='success'>
              <Image src={icon} alt='welcome-icon' />
            </Styled.ImageCont>
            <h4>{heading}</h4>
            <p>{info}</p>
          </FlexCol>

          <Image
            src={CloseSvg}
            alt='close-icon'
            className='close'
            onClick={handleClose}
          />
        </Styled.InfoModalContainer>
      </Modal>
    </CustomModal>
  )
}

export default InfoModal
