import styled from 'styled-components'
import { colors, screenSizes } from 'styles/theme'

export const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.secondary};
  padding: 20px 72px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  max-width: 1920px;
  margin: 0 auto;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }
  @media (max-width: ${screenSizes.XS}px) {
    padding: 18px 24px;
  }
  @media (max-width: 425px) {
    padding: 18px 20px;
  }
  border-bottom: 1px solid ${colors.primary};
`

export const NavLinks = styled.div<any>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 48px;

  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
    font-family: var(--font-tomorrow);
    font-weight: 600;
    &:hover {
      filter: brightness(75%);
    }

    @media (max-width: 800px) {
      margin: 0;
      margin-bottom: 1rem;
    }
  }

  .active {
    color: ${colors.primary};
  }

  @media (max-width: 800px) {
    display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};
    width: 100%;
    margin-top: 40px;
    justify-content: start;
    gap: 44px;

    a {
      margin-bottom: 0;
    }

    .register {
      grid-row: 1/2;

      button {
        display: block;
        margin: 0 auto;
      }
    }

    margin-bottom: 38px;
  }
`

export const MenuIcon = styled.div<any>`
  display: none;

  @media (max-width: 800px) {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 18px;
  }
`

export const OtpContainer = styled.div`
  & > div {
    display: flex;
    gap: 16px;

    input {
      height: 40px;
      width: 40px;
      border: none;
      color: white;
      font-size: 16px;
      flex: 1;
      background: ${colors.inputBg};
      border: 1px solid rgba(255, 255, 255, 0.4);

      @media (min-width: ${screenSizes.M}px) {
        height: 78px;
        width: 78px;
        font-size: 24px;
      }
    }
  }
`

export const SuccessHeading = styled.p`
  text-transform: uppercase;
  font-size: 24px;
  font-family: var(--font-tomorrow);
  font-weight: 500;
`

export const ForgotText = styled.p`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-dm-mono);
  font-weight: 400;
`
