import Image from 'next/image'

import { WalletCardContainer } from './style'
import { FlexRow } from 'styles/styled'

interface IWalletCardProps {
  isNotWallet?: boolean
  text?: string
  icon?: string
  isActive?: boolean
}

const WalletCard = (props: IWalletCardProps) => {
  const { isNotWallet, text, isActive, icon = '' } = props
  return (
    <WalletCardContainer isActive={isActive}>
      {isNotWallet ? (
        <p>Create wallet Using Registered Email Address</p>
      ) : (
        <FlexRow justifyContent='space-between'>
          <p>{text}</p>
          <Image src={icon} alt='wallet-icon' />
        </FlexRow>
      )}
    </WalletCardContainer>
  )
}

export default WalletCard
