import * as Styled from './style'

interface CustomModalProps {
  show: boolean
  children: React.ReactNode
  handleClose?: () => void
  className?: string
  disableBackClick?: boolean
}

const CustomModal = (props: CustomModalProps) => {
  const {
    show,
    children,
    handleClose,
    className,
    disableBackClick = false,
  } = props

  return (
    <>
      <Styled.CustomModalContainerBackgroundBlur show={show} />
      <Styled.CustomModalContainer
        show={show}
        onClick={disableBackClick ? null : handleClose}
      >
        <Styled.ModalBody
          className={className}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </Styled.ModalBody>
      </Styled.CustomModalContainer>
    </>
  )
}
export default CustomModal
