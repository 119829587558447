import Image from 'next/image'
import * as Styles from './style'

import TopLeft from 'public/assets/split/topLeft.svg'
import TopRight from 'public/assets/split/topRight.svg'
import BottomLeft from 'public/assets/split/bottomLeft.svg'
import BottomRight from 'public/assets/split/bottomRight.svg'
import BottomRightProfile from 'public/assets/split/bottomRightProfile.svg'

interface ISplitCardProps {
  children: React.ReactNode
  className?: string
  isProfileNft?: boolean
}

const SplitCard = ({
  children,
  className,
  isProfileNft = false,
}: ISplitCardProps) => {
  return (
    <Styles.MainContainer>
      <Styles.Container>
        {/* Clipping Corners */}
        <Styles.SplitCardContClipTR>
          <Styles.SplitCardContClip>
            <Styles.TextCont className={className}>{children}</Styles.TextCont>
            <Styles.Gradient />
          </Styles.SplitCardContClip>
        </Styles.SplitCardContClipTR>

        {/* Corner Elements */}
        <Styles.TopLeftCont>
          <Image src={TopLeft} alt='image-1' />
        </Styles.TopLeftCont>
        <Styles.TopRightCont>
          <Image src={TopRight} alt='image-2' />
        </Styles.TopRightCont>
        <Styles.BottomLeftCont>
          <Image src={BottomLeft} alt='image-3' />
        </Styles.BottomLeftCont>
        <Styles.BottomRightCont>
          <Image
            src={isProfileNft ? BottomRightProfile : BottomRight}
            alt='image-4'
          />
        </Styles.BottomRightCont>
      </Styles.Container>
      <Styles.BottomShadow />
      <Styles.TopShadow />
    </Styles.MainContainer>
  )
}

export default SplitCard
