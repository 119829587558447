import styled, { keyframes } from 'styled-components'

import { colors } from 'styles/theme'

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const ImageCont = styled.div`
  display: flex;
  justify-content: left;

  img {
    animation: ${rotateAnimation} 2s linear infinite;
  }
`

export const SmallLoaderCont = styled.span`
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-bottom-color: ${colors.primary};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  right: 25px;

  &.large {
    position: static;
    width: 60px;
    height: 60px;
    border-width: 6px;
  }

  &.largeGallery {
    position: static;
    width: 60px;
    height: 60px;
    border-width: 6px;
    margin-left: 300px;
    margin-top: 30px;
  }
`
