import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'

import * as Styles from './style'
import GlitchLogo from '../glitchLogo'
import CustomModal from '../modals/CustomModal'
import Modal from '../modals/Modal'
import Input from '../input'
import { ErrorMsg, FlexCol, FlexRow } from 'styles/styled'
import LogOutIcon from 'public/assets/landing/logout.svg'
import { useAppDispatch, useAppSelector } from 'src/logic/redux/hooks'
import { isValidEmail } from 'shared/helpers'
import {
  setShowConnectModal,
  setShowRegisterModal,
  setShowWrongNetworkModal,
} from 'src/logic/redux/slices/uiSlice'
import {
  AUTH_MODAL_STATUS,
  createPassword,
  emailVerification,
  loginUser,
  registerUser,
  resetPassword,
  setRegisterProgress,
  setUserToken,
  updatePassword,
} from 'src/logic/redux/slices/authSlice'
import { StorageKey } from 'shared/constants'
import { passwordSchema } from 'shared/validations'
import SplitButton from '../buttons/SplitButton'
import WrongNetworkModal from '../modals/WrongNetworkModal'
import PrimaryButton from '../mintButton/MintButton'
import CloseIcon from 'public/assets/landing/close.svg'
import HamIcon from 'public/assets/landing/hamburger.svg'
import ProfileIcon from 'public/assets/profile/profile.svg'
import {
  WalletTypeEnum,
  getUserDetails,
  logout,
} from 'src/logic/redux/slices/userSlice'
import { WALLET_TYPES, getConnector } from 'src/blockchain'
import { connectSocket } from 'src/logic/redux/slices/socketSlice'
import { ErrorIcon } from 'shared/images'
import { useConnectWallet } from 'src/blockchain/hooks/useConnectWallet'

const NAV_ARRAY = [
  {
    title: 'About us',
    id: 'About-us',
    asPath: '/#About-us',
  },
  {
    title: 'Roadmap',
    id: 'Roadmap',
    asPath: '/#Roadmap',
  },
  {
    title: 'Team',
    id: 'Team',
    asPath: '/#Team',
  },
  {
    title: 'Gallery',
    path: '/gallery',
  },
]

interface InitialPasswordValues {
  createPassword: string
  confirmCreatePassword: string
  oldPassword: string
}

const initialPasswordValues: InitialPasswordValues = {
  createPassword: '',
  confirmCreatePassword: '',
  oldPassword: '',
}

const Navbar = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const socketSlice = useAppSelector((state) => state.socketSlice)
  const { user } = useAppSelector((state) => state.userReducer)
  const { showRegisterModal, showWrongNetworkModal } = useAppSelector(
    (state) => state.uiReducer
  )
  const { registerProgress, token, loading, resetingPassword } = useAppSelector(
    (state) => state.authReducer
  )

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)
  const router = useRouter()

  const { pathname, asPath } = router
  const [otp, setOtp] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    //Connecting Socket
    if (user?._id && !socketSlice?.socketConnected && pathname !== '/payment')
      dispatch(connectSocket({ userId: user?._id }))
  }, [user, pathname])

  const {
    handleBlur,
    handleSubmit,
    errors,
    touched,
    handleChange,
    isValid,
    dirty,
    values,
    resetForm,
  } = useFormik({
    initialValues: initialPasswordValues,
    validationSchema: passwordSchema,
    onSubmit: (values: InitialPasswordValues) => {
      if (registerProgress === AUTH_MODAL_STATUS.UPDATE_PASSWORD) {
        dispatch(
          updatePassword({
            oldPassword: values.oldPassword,
            newPassword: values.createPassword,
            resetForm,
          })
        )
      } else {
        dispatch(
          createPassword({
            email,
            password: values.createPassword,
            resetingPassword,
          })
        )
      }
    },
  })

  const { deactivate, account, activate, connectedWallet } = useConnectWallet()

  const handleWalletConnect = (type: WALLET_TYPES) => {
    try {
      activate(getConnector(type))
    } catch (err) {
      console.log('error in wallet connect ', err)
    }
  }

  const dispatch = useAppDispatch()

  useEffect(() => {
    const getToken = () => {
      if (typeof window == 'undefined') return null
      return localStorage.getItem(StorageKey.token)
        ? localStorage.getItem(StorageKey.token)
        : null
    }

    const accessToken = getToken()
    if (accessToken) {
      dispatch(setUserToken(accessToken))
    }
  }, [typeof window])

  useEffect(() => {
    if (token) {
      dispatch(getUserDetails())
    }
  }, [token])

  useEffect(() => {
    if (user?._id) {
      dispatch(setShowRegisterModal(false))
    }

    //Connecting on initial load
    if (
      user?.walletAddress &&
      (user?.walletType == WalletTypeEnum.METAMASK ||
        user?.walletType == WalletTypeEnum.WALLET_CONNECT)
    ) {
      handleWalletConnect(
        user?.walletType == WalletTypeEnum.METAMASK
          ? WALLET_TYPES.metamask
          : WALLET_TYPES.walletConnect
      )
    }
  }, [user])

  const handleLogOut = () => {
    deactivate(connectedWallet)
    logout()
    localStorage.clear()
    window.location.reload()
  }

  const hanndleResetPassword = () => {
    dispatch(resetPassword({ email }))
  }

  const handleEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(
      registerUser({
        email,
      })
    )
  }

  const handlePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(loginUser({ email, password }))
  }

  const handleResetEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    hanndleResetPassword()
  }

  return (
    <Styles.Wrapper>
      <Link href={'/#home'}>
        <GlitchLogo />
      </Link>
      <Styles.NavLinks isOpen={isMenuOpen}>
        {NAV_ARRAY?.map((item) => (
          <Link
            key={item.title}
            href={item.path ? item?.path : `/#${item.id}`}
            onClick={() => {
              setIsMenuOpen(false)
            }}
            className={
              (item.path && pathname === item.path) || asPath === item.asPath
                ? 'active'
                : ''
            }
          >
            {item.title}
          </Link>
        ))}

        <FlexRow className='register'>
          <div>
            {!user?.walletAddress ? (
              <SplitButton
                onClick={() => {
                  if (token) {
                    dispatch(setShowConnectModal(true))
                  } else {
                    dispatch(setShowRegisterModal(true))
                    dispatch(setRegisterProgress(AUTH_MODAL_STATUS.ENTER_EMAIL))
                  }
                }}
                width='140px'
                height='48px'
                disabled={token ? !user?._id : false}
              >
                {token ? 'Connect Wallet' : 'Register/Login'}
              </SplitButton>
            ) : null}
            {user?.walletAddress ? (
              <SplitButton
                onClick={() => {
                  router?.push('/profile?view=nfts')
                }}
                className='icon'
                title='Profile'
              >
                <Image src={ProfileIcon} alt='profile-icon' />
              </SplitButton>
            ) : null}
          </div>

          {(user?._id || account) && (
            <SplitButton
              width='48px'
              onClick={handleLogOut}
              borderColor='#B70018'
              height='48px'
              bgColor='rgba(0, 12, 28, 0.8)'
              className='icon'
              title='Logout'
            >
              <Image src={LogOutIcon} alt='success-icon' />
            </SplitButton>
          )}
        </FlexRow>
      </Styles.NavLinks>
      <Styles.MenuIcon onClick={toggleMenu} isOpen={isMenuOpen}>
        <SplitButton className='icon' title='Navbar'>
          <Image src={isMenuOpen ? CloseIcon : HamIcon} alt='success-icon' />
        </SplitButton>
      </Styles.MenuIcon>

      {/* ================= Modals ================= */}

      <CustomModal
        show={showRegisterModal}
        handleClose={() => dispatch(setShowRegisterModal(false))}
        disableBackClick={registerProgress !== AUTH_MODAL_STATUS.ENTER_EMAIL}
      >
        {registerProgress === AUTH_MODAL_STATUS.ENTER_EMAIL && (
          <Modal
            heading='Enter Email'
            info='Enter the email address to register/login.'
            hideBtn
          >
            <form onSubmit={handleEmailSubmit}>
              <FlexCol gap='36px'>
                <Input
                  type='email'
                  label='Email'
                  placeholder='Enter email'
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete='on'
                  id='email'
                />

                <PrimaryButton
                  disabled={!isValidEmail(email)}
                  fillOpacity='1'
                  isLoading={loading}
                >
                  Continue
                </PrimaryButton>
              </FlexCol>
            </form>
          </Modal>
        )}
        {registerProgress === AUTH_MODAL_STATUS.VERIFY_OTP && (
          <Modal
            heading='Verify otp'
            info={
              <>
                The verification code has been sent to <span>{email}</span>
              </>
            }
            onBtnClick={() => {
              dispatch(
                emailVerification({
                  email,
                  otp,
                })
              )
            }}
            isBtnDisabled={!otp || otp.length !== 6}
            isLoading={loading}
            fillOpacity='1'
          >
            <Styles.OtpContainer>
              <OtpInput
                inputType='tel'
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus
              />
            </Styles.OtpContainer>
          </Modal>
        )}
        {registerProgress === AUTH_MODAL_STATUS.CREATE_PASSWORD && (
          <Modal
            heading='create password'
            info='Create password for your VTR account'
            hideBtn
            fillOpacity='1'
          >
            <form onSubmit={handleSubmit}>
              <FlexCol gap='38px'>
                <FlexCol gap='10px'>
                  <Input
                    name='createPassword'
                    type='password'
                    label='Password'
                    placeholder='Enter password'
                    autoComplete='off'
                    isPassWord
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.createPassword && errors.createPassword && (
                    <ErrorMsg>{errors.createPassword}</ErrorMsg>
                  )}
                </FlexCol>
                <FlexCol gap='10px'>
                  <Input
                    name='confirmCreatePassword'
                    type='password'
                    label='Confirm Password'
                    placeholder='Enter password'
                    isPassWord
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {touched.confirmCreatePassword &&
                    errors.confirmCreatePassword && (
                      <ErrorMsg>{errors.confirmCreatePassword}</ErrorMsg>
                    )}
                </FlexCol>

                <PrimaryButton
                  type='submit'
                  isLoading={loading}
                  disabled={!(isValid && dirty)}
                  fillOpacity='1'
                >
                  Continue
                </PrimaryButton>
              </FlexCol>
            </form>
          </Modal>
        )}
        {registerProgress === AUTH_MODAL_STATUS.ENTER_PASSWORD && (
          <Modal
            heading='enter password'
            info='Enter password for your VTR account'
            hideBtn
          >
            <form onSubmit={handlePasswordSubmit}>
              <FlexCol gap='36px'>
                <FlexCol gap='16px'>
                  <Input
                    type='password'
                    label='Password'
                    placeholder='Enter password'
                    autoComplete='off'
                    onChange={(e) => setPassword(e.target.value)}
                    isPassWord
                  />

                  <Styles.ForgotText
                    className='hyper-link'
                    onClick={() => {
                      dispatch(
                        setRegisterProgress(AUTH_MODAL_STATUS.RESET_PASSWORD)
                      )
                    }}
                  >
                    Forgot Password?
                  </Styles.ForgotText>
                </FlexCol>

                <PrimaryButton
                  type='submit'
                  disabled={!password}
                  isLoading={loading}
                  fillOpacity='1'
                >
                  Continue
                </PrimaryButton>
              </FlexCol>
            </form>
          </Modal>
        )}
        {registerProgress === AUTH_MODAL_STATUS.RESET_PASSWORD && (
          <Modal
            heading='Reset password'
            info='Enter the email address and we’ll send you an otp to reset your password.'
            hideBtn
          >
            <form onSubmit={handleResetEmailSubmit}>
              <FlexCol gap='36px'>
                <Input
                  label='Email'
                  placeholder='Email'
                  type='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled
                />

                <PrimaryButton
                  disabled={!isValidEmail(email)}
                  fillOpacity='1'
                  isLoading={loading}
                >
                  Continue
                </PrimaryButton>
              </FlexCol>
            </form>
          </Modal>
        )}
        {registerProgress === AUTH_MODAL_STATUS.UPDATE_PASSWORD && (
          <Modal
            heading='update password'
            info='Update password for your VTR account'
            hideBtn
            fillOpacity='1'
            hasCloseBtn
            onCloseClick={() => {
              dispatch(setShowRegisterModal(false))
              resetForm()
            }}
          >
            <form onSubmit={handleSubmit}>
              <FlexCol gap='38px'>
                <FlexCol gap='10px'>
                  <Input
                    name='oldPassword'
                    type='password'
                    label='Old Password'
                    placeholder='Enter password'
                    autoComplete='off'
                    isPassWord
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.oldPassword}
                  />
                  {touched.createPassword && errors.createPassword && (
                    <ErrorMsg>{errors.createPassword}</ErrorMsg>
                  )}
                </FlexCol>
                <FlexCol gap='10px'>
                  <Input
                    name='createPassword'
                    type='password'
                    label='New Password'
                    placeholder='Enter password'
                    autoComplete='off'
                    isPassWord
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.createPassword}
                  />
                  {touched.createPassword && errors.createPassword && (
                    <ErrorMsg>{errors.createPassword}</ErrorMsg>
                  )}
                </FlexCol>
                <FlexCol gap='10px'>
                  <Input
                    name='confirmCreatePassword'
                    type='password'
                    label='Confirm Password'
                    placeholder='Enter password'
                    isPassWord
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.confirmCreatePassword}
                  />

                  {touched.confirmCreatePassword &&
                    errors.confirmCreatePassword && (
                      <ErrorMsg>{errors.confirmCreatePassword}</ErrorMsg>
                    )}
                </FlexCol>

                <PrimaryButton
                  type='submit'
                  isLoading={loading}
                  disabled={!(isValid && dirty)}
                  fillOpacity='1'
                >
                  Continue
                </PrimaryButton>
              </FlexCol>
            </form>
          </Modal>
        )}
      </CustomModal>

      {showWrongNetworkModal && (
        <WrongNetworkModal
          onBtnClick={() => dispatch(setShowWrongNetworkModal(false))}
          show={showWrongNetworkModal}
          handleClose={() => {}}
          heading='SWITCH NETWORK'
          info='Wrong network detected, switch or disconnect to continue.'
          icon={ErrorIcon}
        />
      )}
    </Styles.Wrapper>
  )
}

export default Navbar
