import Image, { StaticImageData } from 'next/image'
import { InputHTMLAttributes, useState } from 'react'

import * as Styles from './style'
import { PasswordHideIcon, PasswordShowIcon } from 'shared/images'

interface IInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    Styles.IStyledInputProps {
  label?: string
  icon?: string | StaticImageData
  gap?: string
  isPassWord?: boolean
  reverseIconPosition?: boolean
}

const Input = (props: IInputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const {
    label,
    reverseIconPosition,
    isPassWord,
    type,
    className,
    icon = '',
    gap = '8px',
    ...otherProps
  } = props

  return (
    <Styles.InputWithLabelCont gap={gap}>
      {label && <label htmlFor={otherProps?.id}>{label}</label>}
      <div>
        <Styles.InputInnerCont>
          <Styles.StyledInput
            {...otherProps}
            type={showPassword ? 'text' : type}
          />
          {isPassWord && (
            <Image
              src={showPassword ? PasswordHideIcon : PasswordShowIcon}
              alt='input-icon'
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </Styles.InputInnerCont>
      </div>
    </Styles.InputWithLabelCont>
  )
}

export default Input

export const InputWithIcon = (props: IInputProps) => {
  const {
    label,
    reverseIconPosition,
    isPassWord,
    type,
    className,
    icon = '',
    gap = '8px',
    ...otherProps
  } = props
  return (
    <Styles.InputContainer
      gap={gap}
      reverseIconPosition={isPassWord ? true : reverseIconPosition}
      className={className}
    >
      {!isPassWord && <Image src={icon} alt='input-icon' />}

      <Styles.StyledInput type={'text'} {...otherProps} />
    </Styles.InputContainer>
  )
}
