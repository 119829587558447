import { useEffect, useState } from 'react'
import { NoMetaMaskError } from '@web3-react/metamask'

import { FlexCol, FlexRow, DividerText } from 'styles/styled'
import CustomModal from './CustomModal'
import Modal from './Modal'
import WalletCard from './components/WalletCard'
import MetamaskIcon from 'public/assets/landing/metamask.svg'
import WalletConnectIcon from 'public/assets/landing/walletConnect.svg'
import { useAppDispatch, useAppSelector } from 'src/logic/redux/hooks'
import {
  setShowConnectModal,
  setShowConnectWalletStatusModal,
  setShowWrongNetworkModal,
  showToast,
} from 'src/logic/redux/slices/uiSlice'
import { WALLET_TYPES, getConnector } from 'src/blockchain'
import {
  WalletTypeEnum,
  createWallet,
  setWalletVerificationStatus,
  verifyingSignIn,
} from 'src/logic/redux/slices/userSlice'
import { ToastType } from 'shared/constants'
import { useConnectWallet } from 'src/blockchain/hooks/useConnectWallet'

export enum CONNECTION_TYPE {
  METAMASK = 'metamask',
  WALLET_CONNECT = 'walletConnect',
  EMAIL = 'email',
}

const ConnectModal = () => {
  const [connectType, setConnectType] = useState('')

  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.userReducer)

  const [walletType, setWalletType] = useState<WalletTypeEnum>()
  const { activate, account, active, error, provider } = useConnectWallet()

  const handleConnection = (connectType: string) => {
    switch (connectType) {
      case CONNECTION_TYPE.METAMASK:
        handleWalletConnect(WALLET_TYPES.metamask)
        setWalletType(WalletTypeEnum.METAMASK)
        break
      case CONNECTION_TYPE.WALLET_CONNECT:
        handleWalletConnect(WALLET_TYPES.walletConnect)
        setWalletType(WalletTypeEnum.WALLET_CONNECT)
        break
      case CONNECTION_TYPE.EMAIL:
        dispatch(createWallet())
        dispatch(setShowConnectModal(false))
        dispatch(setShowConnectWalletStatusModal(true))
        dispatch(setWalletVerificationStatus('STARTED'))
        setWalletType(WalletTypeEnum.CUSTODIAL_WALLET)
        break
      default:
        break
    }
  }

  const handleWalletConnect = (type: WALLET_TYPES) => {
    try {
      activate(getConnector(type))
    } catch (err) {
      console.log('error in connecting wallet ', err)
    }
  }

  useEffect(() => {
    if (
      error &&
      (error as any)?.code === 4902 &&
      (walletType === WalletTypeEnum.METAMASK ||
        walletType === WalletTypeEnum.WALLET_CONNECT)
    ) {
      dispatch(setShowWrongNetworkModal(true))
      dispatch(setShowConnectModal(false))
    }
    if (
      // Metamask not found
      error &&
      (error as any) instanceof NoMetaMaskError &&
      walletType === WalletTypeEnum.METAMASK
    ) {
      dispatch(
        showToast({
          message: 'Metamask not found!',
          toastType: ToastType.error,
        })
      )
    }
  }, [error])

  useEffect(() => {
    // Updating WalletAddress in Database

    if (account && !user?.walletAddress && user?._id && walletType) {
      dispatch(setShowConnectModal(false))
      dispatch(setShowConnectWalletStatusModal(true))
      dispatch(setWalletVerificationStatus('STARTED'))
      verifyingSignIn(account, walletType, dispatch, provider)
    }
  }, [active, account, walletType])

  return (
    <CustomModal
      show={true}
      handleClose={() => dispatch(setShowConnectModal(false))}
    >
      <Modal
        heading='Connect with'
        onBtnClick={() => handleConnection(connectType)}
        isBtnDisabled={!connectType}
        fillOpacity='0.5'
      >
        <FlexCol gap='24px' alignItems='stretch'>
          <FlexCol gap='16px' alignItems='stretch'>
            <div onClick={() => setConnectType(CONNECTION_TYPE.METAMASK)}>
              <WalletCard
                text='Metamask'
                icon={MetamaskIcon}
                isActive={connectType === CONNECTION_TYPE.METAMASK}
              />
            </div>

            <div onClick={() => setConnectType(CONNECTION_TYPE.WALLET_CONNECT)}>
              <WalletCard
                text='Wallet Connect'
                icon={WalletConnectIcon}
                isActive={connectType === CONNECTION_TYPE.WALLET_CONNECT}
              />
            </div>
          </FlexCol>

          <FlexRow gap='8px' justifyContent='center' alignItems='center'>
            <hr />
            <DividerText>or use email</DividerText>
            <hr />
          </FlexRow>
          <div onClick={() => setConnectType(CONNECTION_TYPE.EMAIL)}>
            <WalletCard
              isNotWallet
              isActive={connectType === CONNECTION_TYPE.EMAIL}
            />
          </div>
        </FlexCol>
      </Modal>
    </CustomModal>
  )
}

export default ConnectModal
