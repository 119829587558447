import { ButtonHTMLAttributes } from 'react'

import * as Styles from './style'

export interface ISplitButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  borderColor?: string
  bgColor?: string
  height?: string
  width?: string
  children?: React.ReactNode
  padding?: string
  fillOpacity?: string
}

const SplitButton = (props: ISplitButtonProps) => {
  const { bgColor, padding, children, ...otherProps } = props
  return (
    <Styles.SplitButtonCont {...otherProps}>
      <Styles.SplitButtonInside bgColor={bgColor} padding={padding}>
        {children}
      </Styles.SplitButtonInside>
    </Styles.SplitButtonCont>
  )
}

export default SplitButton
